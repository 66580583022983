<template>
  <div>
    <nav class="navbar top" role="navigation" aria-label="main navigation">
      <div class="navbar-brand is-flex-grow-1">
        <div class="navbar-item is-justify-content-space-between is-flex-grow-1">
          <h1 class="title mb-0">
            Dashboard
          </h1>
          <NewCampaignButton />
        </div>
      </div>
    </nav>

    <section v-if="user && campaigns" class="section has-border">
      <div class="level is-mobile">
        <h3 class="subtitle is-size-3 mb-0">
          <span class="is-hidden-mobile">Your</span> Campaigns
        </h3>
        <PanelTabs
          class="is-hidden-tablet"
          :options="[
            { label: 'Active', value: 'active' },
            { label: 'Completed', value: 'completed' },
          ]"
          v-model="currentFilter"
        />
        <PanelTabs
          class="is-hidden-mobile"
          :options="[
            { label: 'Drafts', value: 'drafts' },
            { label: 'Live', value: 'active' },
            { label: 'Ready', value: 'ready' },
            { label: 'Processing', value: 'processing' },
            { label: 'Completed', value: 'completed' },
          ]"
          v-model="currentFilter"
        />
      </div>

      <div v-if="currentFilter === 'active'" class="table-container">
        <table class="table is-fullwidth">
          <thead v-if="readyCampaigns.length" class="has-background-light is-hidden-tablet">
            <tr>
              <th colspan="4">Ready for Submission</th>
            </tr>
          </thead>
          <tbody class="is-hidden-tablet">
            <CampaignRow
              v-for="campaign in readyCampaigns"
              :key="campaign.id"
              :campaign="campaign"
              :invites="getInvites(campaign.id) ?? []"
            />
          </tbody>
          <thead v-if="processingCampaigns.length" class="has-background-light is-hidden-tablet">
            <tr>
              <th colspan="4">Processing</th>
            </tr>
          </thead>
          <tbody class="is-hidden-tablet">
            <CampaignRow
              v-for="campaign in processingCampaigns"
              :key="campaign.id"
              :campaign="campaign"
              :invites="getInvites(campaign.id) ?? []"
            />
          </tbody>
          <thead v-if="liveCampaigns.length" class="has-background-light">
            <tr>
              <th colspan="4">Live Campaigns</th>
            </tr>
          </thead>
          <tbody>
            <CampaignRow
              v-for="campaign in liveCampaigns"
              :key="campaign.id"
              :campaign="campaign"
              :invites="getInvites(campaign.id) ?? []"
            />
          </tbody>
          <thead v-if="draftCampaigns.length" class="has-background-light is-hidden-tablet">
            <tr>
              <th colspan="4">Draft</th>
            </tr>
          </thead>
          <tbody class="is-hidden-tablet">
            <CampaignRow
              v-for="campaign in draftCampaigns"
              :key="campaign.id"
              :campaign="campaign"
              :invites="getInvites(campaign.id) ?? []"
            />
          </tbody>
        </table>
      </div>

      <div v-if="currentFilter === 'ready'" class="table-container">
        <table class="table is-fullwidth">
          <thead class="has-background-light">
            <tr>
              <th colspan="4">Ready for submission</th>
            </tr>
          </thead>
          <tbody v-if="readyCampaigns.length">
            <CampaignRow
              v-for="campaign in readyCampaigns"
              :key="campaign.id"
              :campaign="campaign"
              :invites="getInvites(campaign.id) ?? []"
            />
          </tbody>
          <tbody v-else>
            <p class="has-text-centered has-text-weight-bold mt-5">No campaigns ready for submission</p>
          </tbody>
        </table>
      </div>

      <div v-if="currentFilter === 'drafts'" class="table-container">
        <table class="table is-fullwidth">
          <thead class="has-background-light">
            <tr>
              <th colspan="4">Draft</th>
            </tr>
          </thead>
          <tbody v-if="draftCampaigns.length">
            <CampaignRow
              v-for="campaign in draftCampaigns"
              :key="campaign.id"
              :campaign="campaign"
              :invites="getInvites(campaign.id) ?? []"
            />
          </tbody>
          <tbody v-else>
            <p class="has-text-centered has-text-weight-bold mt-5">No draft campaigns</p>
          </tbody>
        </table>
      </div>

      <div v-if="currentFilter === 'processing'" class="table-container">
        <table class="table is-fullwidth">
          <thead class="has-background-light">
            <tr>
              <th colspan="4">Processing</th>
            </tr>
          </thead>
          <tbody v-if="processingCampaigns.length">
            <CampaignRow
              v-for="campaign in processingCampaigns"
              :key="campaign.id"
              :campaign="campaign"
              :invites="getInvites(campaign.id) ?? []"
            />
          </tbody>
          <tbody v-else>
            <p class="has-text-centered has-text-weight-bold mt-5">No processing campaigns</p>
          </tbody>
        </table>
      </div>

      <div v-if="currentFilter === 'completed'" class="table-container">
        <table class="table is-striped is-fullwidth">
          <thead v-if="completedCampaigns.length" class="has-background-light">
            <tr>
              <th colspan="4">Completed</th>
            </tr>
          </thead>
          <tbody>
            <CampaignRow
              v-for="campaign in completedCampaigns"
              :key="campaign.id"
              :campaign="campaign"
              :invites="getInvites(campaign.id) ?? []"
            />
          </tbody>
        </table>
      </div>
    </section>

    <section v-if="user && invites" class="section has-padding-bottom">
      <div class="level is-mobile">
        <h3 class="subtitle is-size-3 mb-0">
          Recipients
        </h3>
        <PanelTabs
          :options="[
            { label: 'Sent', value: 'sent' },
            { label: 'Received', value: 'received' },
          ]"
          v-model="currentInviteFilter"
        />
      </div>

      <div class="table-container">
        <table class="table is-fullwidth">
          <tbody>
            <InviteRow
              v-for="invite in filteredInvites"
              :key="invite.id"
              :invite="invite"
              :showCampaign="true"
            />
          </tbody>
          <tbody v-if="!filteredInvites.length">
            <tr>
              <td class="has-text-centered p-6 has-text-weight-bold" colspan="6">
                No {{ currentInviteFilter }} campaigns
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

  </div>
</template>

<script>
import { ref, computed } from 'vue'
import getUser from '@/composables/getUser'
import getCollection from '@/composables/getCollection'
import NewCampaignButton from '@/components/CreateCampaign/NewCampaignButton.vue'
import PanelTabs from '@/components/Campaign/PanelTabs.vue'
import CampaignRow from '@/components/Campaign/CampaignRow.vue'
import InviteRow from '@/components/Campaign/InviteRow.vue'
import filters from '@/utils/filters'
import { campaignIsCompleted } from '@/utils/utils'

export default {
  components: { NewCampaignButton, PanelTabs, CampaignRow, InviteRow },
  setup() {
    const { user } = getUser()
    const { documents: campaigns } = getCollection('campaigns', ['accountId', '==', user.value.uid])
    const { documents: invites } = getCollection('invites', ['accountId', '==', user.value.uid])
    
    const currentFilter = ref('active')

    const readyCampaigns = computed(() => {
      return campaigns.value && campaigns.value.filter(campaign => {
        if (!campaign.launchedAt || campaign.submittedAt) return

        if (new Date().getTime() > campaign.expiryDate.seconds * 1000) {
          return true
        }

        if (campaign.mode === 'conference') return

        const invites = getInvites(campaign.id)
        if (!invites) return
        const acceptedInvites = invites.filter(invite => invite.acceptedAt)
        if (acceptedInvites.length === invites.length) {
          return true
        }
      })
    })

    const liveCampaigns = computed(() => {
      return campaigns.value && campaigns.value.filter(campaign => {
        if (!campaign.launchedAt || campaign.submittedAt) return
        return !readyCampaigns.value.some(readyCampaign => readyCampaign.id === campaign.id)
      })
    })

    const draftCampaigns = computed(() => {
      return campaigns.value && campaigns.value.filter(campaign => !campaign.launchedAt)
    })

    const processingCampaigns = computed(() => {
      return campaigns.value && campaigns.value.filter(campaign => campaign.submittedAt && !campaign.completedAt)
    })

    const completedCampaigns = computed(() => {
      return campaigns.value && campaigns.value.filter(campaign => campaign.completedAt)
    })

    const getInvites = (campaignId) => {
      return invites.value && invites.value.filter(invite => invite.campaignId === campaignId)
    }

    const currentInviteFilter = ref('sent')

    const filteredInvites = computed(() => {
      if (!invites.value) return []
      if (currentInviteFilter.value === 'sent') {
        return invites.value.filter(invite => !invite.acceptedAt && !invite.declinedAt && !invite.request)
      }
      if (currentInviteFilter.value === 'received') {
        return invites.value.filter(invite => invite.acceptedAt || invite.request)
      }
    })

    return {
      user,
      campaigns,
      invites,
      campaignIsCompleted,

      currentFilter,
      liveCampaigns,
      readyCampaigns,
      draftCampaigns,
      processingCampaigns,
      completedCampaigns,

      getInvites,

      currentInviteFilter,
      filteredInvites,

      ...filters
    }
  }
}
</script>

<style>
@media screen and (min-width: 769px) {
  .tabs a {
    min-width: 92px;
  }
}
</style>